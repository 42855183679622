import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';
import { FacturaService } from 'src/app/services/factura.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CursServiceService } from 'src/app/services/curs-service.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  loginDisplay: boolean = true;
  userName: string;
  userEmail: string;
  user: any;
  cursValutarStr: string;
  cursValutar: FormGroup;
  constructor(private _cursValutar: CursServiceService, private formBuilder: FormBuilder, private _userService: UserService, private router: Router, private client: ClientService) { }

  ngOnInit() {
    this.buildFormFunc();
    this.client.currentMessage.subscribe(message => {
      if (message == "1") {
        this.loadNavbar();
      }
    })
    this.loadNavbar();
  }

  loadNavbar() {
    this._cursValutar.getCursValutar().subscribe(
      response => {
        if (response.currentDate != response.data) {
          alert("Te rugam sa iti updatezi cursul valutar deoarece nu mai este curs curent, schimbarea cursului se face din Setari => Curs Valutar")
        } else {
          this._userService.setLocalstorageCursValutar(response);
        }
        this.getUserData();
      },
      error => {
        this.getUserData();
      },
      () => {}
    )
  }


  getUserData = function () {
    var user = this._userService.getLocalstorageUser();
    var curs = this._userService.getLocalstorageCursValutar();
    if (user) {
      user = JSON.parse(user);
      this.user = user;
      this.userName = user.name;
      this.userEmail = user.email;
      this.loginDisplay = false;
    } else {
      this.userEmail = 'Authentificare';
    }
    if (curs) {
      curs = JSON.parse(curs);
      this.cursValutarStr = curs.cursCurent;
    }
  }

  logout() {
    localStorage.removeItem('Authentication');
    localStorage.removeItem('User');
    this.router.navigate(['/login']);
  }


  onSubmitCursValutar(obj) {
    this.cursValutar.value.userId = this.user.id;
    this._cursValutar.insertCursValutar(this.cursValutar.value).subscribe(
      response => {
        this.loadNavbar();
        $("#addCursValutar .close").click();
      },
      error => {},
      () => {}
    )
  }

  buildFormFunc() {
    this.cursValutar = this.formBuilder.group({
      cursCurent: ['', Validators.required],
      userId: ['', Validators.nullValidator]
    });
  }

}
