import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  // private _baseAddress: string = "http://www.app.owlsoftware.ro/api/";
  // private _baseAddress: string = "http://localhost:8888/api/";
  private _baseAddress: string = "http://www.kovexapp.ro/api/";
  
  private messageSource = new BehaviorSubject('0');
  currentMessage = this.messageSource.asObservable();

  constructor(private _http: HttpClient) { }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  getClientById(id){
    var address = this._baseAddress + 'client/' + id;  
    return this._http.get<any>(address, { headers: this._buildHeader() });  
  }

  getClients(){
    var address = this._baseAddress + 'client';  
    return this._http.get<any[]>(address, { headers: this._buildHeader() });  
  }
  insertClient(client){
    var address = this._baseAddress + 'client';  
    return this._http.post(address, client, { headers: this._buildHeader() });  
  }
  insertPersoanaContact(persoanaContact){
    var address = this._baseAddress + 'persoanaContact';  
    return this._http.post(address, persoanaContact, { headers: this._buildHeader() });  
  }
  deleteClient(id){
    var address = this._baseAddress + 'client/' + id;  
    return this._http.delete(address, { headers: this._buildHeader() });  
  }
  updateClient(client){
    var address = this._baseAddress + 'client';  
    return this._http.put(address, client, { headers: this._buildHeader() });  
  }
  private _buildHeader(isFile: boolean = false): HttpHeaders {

    let headers = new HttpHeaders();
    let authTokken = localStorage['Authentication'];
    if(isFile){
      // headers = headers.append('Content-Type', 'multipart/form-data');
    }else{
      headers = headers.append('Content-Type', 'application/json');
    }
    headers = headers.append('Authorization', authTokken);
    return headers;
  }
}
