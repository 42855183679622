import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { HttpClientModule }    from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './modules/app-routing/app-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ClientComponent } from './components/client/client.component';
import { ProdusComponent } from './components/produs/produs.component';
import { LoginComponent } from './components/login/login.component';
import { OfertareComponent } from './components/ofertare/ofertare/ofertare.component';
import * as $ from 'jquery';
import { OfertaComponent } from './components/oferta/oferta/oferta.component';
import { OfertaViewComponent } from './components/oferta/oferta-view/oferta-view.component';
import { FacturaComponent } from './components/factura/factura/factura.component';
import { OfertaEditComponent } from './components/oferta/oferta-edit/oferta-edit.component';
import { FacturaViewComponent } from './components/factura/factura-view/factura-view.component';
import { FacturaEditComponent } from './components/factura/factura-edit/factura-edit.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavbarComponent,
    ClientComponent,
    ProdusComponent,
    LoginComponent,
    OfertareComponent,
    OfertaComponent,
    OfertaViewComponent,
    FacturaComponent,
    OfertaEditComponent,
    FacturaViewComponent,
    FacturaEditComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule ,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
