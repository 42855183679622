import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductsService } from '../../../services/products.service';
import { ClientService } from 'src/app/services/client.service';
import { UserService } from 'src/app/services/user.service';
import { OfertaService } from 'src/app/services/oferta.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ofertare',
  templateUrl: './ofertare.component.html',
  styleUrls: ['./ofertare.component.css']
})
export class OfertareComponent implements OnInit {
  notReadyForFacturare: boolean = true;
  ofertaPretTotal: number = 0;
  ofertaPretTotalLei: number = 0;
  isStockSatuMare: boolean = false;
  clientForm: FormGroup;
  filterProductVal: string;
  filterProductVal2: string;
  avansTermenDate: string = "";
  tipClient: number = 0;
  mentiuneId: number = 0;
  procentajAvans: number = 0;

  client: any;
  facturareClient: any;

  cursValutar: any;
  cursValutarInt: number;
  produsIndividualForm: FormGroup;
  oferta: FormGroup;
  clients: any[];
  mentiuni: any[];
  products: any[];
  productsBase: any[];
  productsForOferta: any[] = [];

  constructor(private formBuilder: FormBuilder, private router: Router, private _productService: ProductsService, private _clientService: ClientService, private _userService: UserService, private _ofertaServce: OfertaService) { }

  ngOnInit() {
    this.onloading(1);
    this.buildFormFunc(null);
  }

  onloading(option) {
    this.cursValutar = this._userService.getLocalstorageCursValutar();
    if (this.cursValutar) {
      this.cursValutar = JSON.parse(this.cursValutar);
      this.cursValutarInt = parseFloat(this.cursValutar.cursCurent)
    }
    if (option == 1) {
      this._productService.getProduse().subscribe(
        response => {
          this.products = response;
          this.productsBase = response;
        },
        error => {},
        () => {}
      )
      this._clientService.getClients().subscribe(
        response => {
          this.clients = response;
        },
        error => {},
        () => {}
      )
      this._productService.getMentiuni().subscribe(
        response => {
          this.mentiuni = response
        },
        error => {},
        () => {}
      )
    } else if (option == 2) {
      this._clientService.getClients().subscribe(
        response => {
          this.clients = response;
        },
        error => {},
        () => {}
      )
    }
  }
  loadProduseSeparat() {
    this._productService.getProduse().subscribe(
      response => {
        this.products = response;
        this.productsBase = response;
      },
      error => {},
      () => {}
    )
  }
  filterData(type) {
    if (type == 1) {
      this.products = this.productsBase.filter(prod => {
        return prod.description.toLowerCase().includes(this.filterProductVal.toLowerCase())
      });
    } else if (type == 2) {
      this.products = this.productsBase.filter(prod => {
        return prod.partNumber.toLowerCase().includes(this.filterProductVal2.toLowerCase())
      });
    }else if(type == 3){
      if (this.isStockSatuMare) {
        this.products = this.productsBase.filter(prod => {
          return prod.stockSatuMare.toLowerCase().includes('1')
        });
      }else {
        this.products = this.productsBase;
      }
    }
  }
  addProdusToOferta(produs) {
    produs.quantity = 1;
    produs.pretFinalCuProcentQuantity = 0;
    var isElm = this.productsForOferta.find(elm => elm.id == produs.id);
    if (!isElm) {
      this.productsForOferta.push(produs);
    }
  }

  stergeProdusDinOferta(produs) {
    var index = this.productsForOferta.indexOf(produs);
    this.productsForOferta.splice(index, 1);
  }

  seteazaTipClient(tipClient) {
    this.tipClient = tipClient;
  }

  onSubmitClient() {
    this._clientService.insertClient(this.clientForm.value).subscribe(
      response => {
        this.client = response;
        this.client = this.client.client;
        this.onloading(2);
        $("#adaugaClient .close").click();
      },
      error => {},
      () => {}
    )
  }

  buildOferta() {
    var user = this._userService.getLocalstorageUser();
    if (user)
      var user2 = JSON.parse(user);

    this.productsForOferta.forEach(product => {
      if (!product.mentiunePozitiePeProdus) {
        product.mentiunePozitiePeProdus = ""
      }
    });
    this.oferta.value.userId = user2.id;
    this.oferta.value.client = this.client;
    this.oferta.value.products = this.productsForOferta;
    this.oferta.value.mentiuneId = this.mentiuneId;
    this.oferta.value.procentajAvans = this.procentajAvans;
    this.oferta.value.avansTermenDate = this.avansTermenDate;
  }

  calculateProcent(pret, procent, produs) {
    if (!produs.transportRon) {
      produs.transportRon = 0;
    }
    pret = pret.toString();
    pret = pret.replace(',', '.');
    pret = pret.replace('EUR', '');
    pret = +pret;

    var valProcentaj = (procent / 100) * pret;
    produs.pretFinalCuProcent = ((+valProcentaj) + +(pret));
    produs.pretFinalCuProcent = produs.pretFinalCuProcent.toFixed(2);

    produs.pretFinalCuProcent = ((+produs.transportRon) + +(produs.pretFinalCuProcent));
    produs.pretFinalCuProcent = produs.pretFinalCuProcent.toFixed(2);

    produs.pretFinalCuProcentLei = this.cursValutarInt * produs.pretFinalCuProcent;
    produs.pretFinalCuProcentLei = produs.pretFinalCuProcentLei.toFixed(2);

    produs.pretFinalCuProcentQuantity = produs.pretFinalCuProcent * produs.quantity;
    produs.pretFinalCuProcentQuantity = produs.pretFinalCuProcentQuantity.toFixed(2);

    produs.pretFinalCuProcentQuantityLei = produs.pretFinalCuProcentLei * produs.quantity;
    produs.pretFinalCuProcentQuantityLei = produs.pretFinalCuProcentQuantityLei.toFixed(2);

    this.calculeazaTotal()
  }

  calculeazaTotal() {
    this.ofertaPretTotal = 0;
    this.productsForOferta.forEach(element => {
      if (element.pretFinalCuProcentQuantityLei) {
        this.ofertaPretTotal = this.ofertaPretTotal + (+element.pretFinalCuProcentQuantityLei);
        this.ofertaPretTotalLei = +this.ofertaPretTotal.toFixed(2);
      }
    });
  }

  factureazaOferta() {
    if (this.tipClient == 1) {
      this.facturareClient = this.client;
    } else if (this.tipClient == 2) {
      this.facturareClient = this.clientForm.value;
    }
  }

  updateProduct(product) {
    this.buildFormFunc(product);
    this.onUpdateProdus();
  }
  insertProduct(product) {
    this.buildFormFuncIndividual(product);
    this.onInsertProdus();
  }

  onUpdateProdus() {
    this._productService.updateIndividualProduct(this.produsIndividualForm.value).subscribe(
      response => {},
      error => {},
      () => {}
    )
  }

  loadBackup() {
    var sBackup = localStorage.getItem("OfertareBackup");
    var jBackup = JSON.parse(sBackup);

    jBackup.products.forEach(product => {
      if (!product.mentiunePozitiePeProdus) {
        product.mentiunePozitiePeProdus = ""
      }
    });

    this.client = jBackup.client;
    this.oferta.value.descriere = jBackup.descriere;
    this.productsForOferta = jBackup.products;
    this.mentiuneId = jBackup.mentiuneId;
    this.procentajAvans = jBackup.procentajAvans;
    this.avansTermenDate = jBackup.avansTermenDate;
  }

  saveBackup() {
    this.buildOferta();
    var sOferta = JSON.stringify(this.oferta.value);
    localStorage.setItem("OfertareBackup",sOferta);
  }

  onInsertProdus() {
    this._productService.insertIndividualProduct(this.produsIndividualForm.value).subscribe(
      response => {
        $("#adaugaProdusIndividual .close").click();
        this.loadProduseSeparat();
      },
      error => {},
      () => {}
    )
  }

  salveazaFactura() {
    this.buildOferta();
    this._ofertaServce.insertOferta(this.oferta.value).subscribe(
      response => {
        this.router.navigate(['/ofertaView', response.id]);
      },
      error => {},
      () => {}
    )
  }

  printeazaFactura() {
    let printContent, printContentCumparator, printableText, popupWin;
    printContent = document.getElementById('printable').innerHTML;
    printableText = document.getElementById('printableText').innerHTML;
    printContentCumparator = document.getElementById('printableCumparator').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>

        <head>
          <style>
            .printableText {
              margin-left:50px;
            }
            .printableText p {
                font-size: 12px;
                margin: 0;
            }
            .printableText h4 {
                font-size: 14px;
                margin: 0;
            }
            table {
                width:100%;
            }
            table, th, td {
                border: 1px solid black;
                border-collapse: collapse;
            }
            th, td {
                padding: 15px;
                text-align: left;
            }
            table#t01 tr:nth-child(even) {
                background-color: #eee;
            }
            table#t01 tr:nth-child(odd) {
                background-color: #fff;
            }
            table#t01 th {
                background-color: black;
                color: white;
            }
          </style>
        </head>

        <body onload="window.print();window.close()">

          <div style="display: flex;justify-content: center;align-items: center;height: 150px;flex-flow: column;">
            <img style="height: auto;max-width: 100px;" src="http://www.kovexapp.ro/kovex.png">
            <h2 style="font-family: monospace;">Factura Proforma: 5506</h2>
          </div>
          <div style="display:flex;height:200px;justify-content: center;align-items: center;">
            <div style="display:flex;width:100%;height:200px;justify-content: center;align-items: center;">
              
              <div style="display:flex;height:200px;width:50%;justify-content: center;align-items: center;flex-flow: column;">
                <div style="display:flex;height:200px;width:80%;justify-content: flex-start;align-items: flex-start;flex-flow: column;">
                  <h2 style="font-family: monospace;margin: 0;">Furnizor: </h2>
                  <h3 style="font-family: monospace;margin: 0;">SC Kovex Srl</h3>
                  <br>
                  <p style="font-family: monospace;margin: 0;">CIF : 1782052 </p>
                  <p style="font-family: monospace;margin: 0;">NR.ORD.REG.COM/AN: J30/875/2005</p>
                  <p style="font-family: monospace;margin: 0;">Sediul : Satu Mare, str Ady Endre 6. </p>
                  <p style="font-family: monospace;margin: 0;">Judetul : Satu Mare </p>
                  <p style="font-family: monospace;margin: 0;">Capital social : 200 </p>
                  <p style="font-family: monospace;margin: 0;">Cod IBAN : RO50OTPV280001128419RO01 </p>
                  <p style="font-family: monospace;margin: 0;">Banca : OTP SUC. SATU MARE </p>
                </div>
              </div>

              <div style="display:flex;height:200px;width:50%;justify-content: center;align-items: center;flex-flow: column;">
               <div style="display:flex;height:200px;width:80%;justify-content: center;align-items: flex-end;flex-flow: column;">
                ${printContentCumparator}
               </div>
              </div>

            </div>
          </div>

          <div>${printContent}</div>
          <div>${printableText}</div>
        </body>

      </html>`
    );

    // <body onload="window.print();window.close()">${printContent}</body>

    popupWin.document.close();
  }
  buildFormFuncIndividual(obj) {
    this.produsIndividualForm = this.formBuilder.group({
      id: [obj.value.id, Validators.required],
      furnizor: [obj.value.furnizor, Validators.required],
      partNumber: [obj.value.partNumber, Validators.required],
      description: [obj.value.description, Validators.required],
      uom: [obj.value.uom, Validators.required],
      re: [obj.value.re, Validators.required],
      stock: [obj.value.stock, Validators.required],
      stockSatuMare: [obj.value.stockSatuMare, Validators.nullValidator],
      produseInStockSM: [obj.value.produseInStockSM, Validators.nullValidator]
    })
  }

  buildFormFunc(obj) {
    if (obj != null) {
      this.produsIndividualForm = this.formBuilder.group({
        id: [obj.id, Validators.required],
        furnizor: [obj.furnizor, Validators.required],
        partNumber: [obj.partNumber, Validators.required],
        description: [obj.description, Validators.required],
        uom: [obj.uom, Validators.required],
        re: [obj.re, Validators.required],
        stock: [obj.stock, Validators.required],
        stockSatuMare: [obj.stockSatuMare, Validators.nullValidator],
        produseInStockSM: [obj.produseInStockSM, Validators.nullValidator],
        mentiunePozitiePeProdus: ['', Validators.nullValidator],
      });
    } else {
      this.clientForm = this.formBuilder.group({
        clientName: ['', Validators.required],
        adresaSediu: ['', Validators.required],
        adresaLivrare: ['', Validators.required],
        cui: ['', Validators.required],
        numarInregistrare: ['', Validators.required],
        contBancar: ['', Validators.required],
        numeBanca: ['', Validators.required]
      });
      this.oferta = this.formBuilder.group({
        products: ['', Validators.required],
        descriere: ['', Validators.required],
        userId: ['', Validators.required],
        client: ['', Validators.required],
        isFacturaProforma: [0, Validators.required],
      });
      this.produsIndividualForm = this.formBuilder.group({
        furnizor: ['', Validators.required],
        partNumber: ['', Validators.required],
        description: ['', Validators.required],
        uom: ['', Validators.required],
        re: ['', Validators.required],
        stock: ['', Validators.required],
        stockSatuMare: ['', Validators.nullValidator],
        produseInStockSM: ['', Validators.nullValidator],
        mentiunePozitiePeProdus: ['', Validators.nullValidator],
      });
    }

  }

}
