import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

import { NavbarComponent } from '../navbar/navbar.component';
import { ClientService } from 'src/app/services/client.service';

@Component({
    providers: [NavbarComponent],
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm : FormGroup;
    submitted = false;
    hideAlert = true;

    constructor(private formBuilder: FormBuilder, private _userService: UserService, private router: Router, private navbarComp: NavbarComponent, private _clientService: ClientService) {}

    ngOnInit() {
        this.checkloggedUser();
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
    }
    get f() { return this.loginForm.controls; }

    checkloggedUser() {
        var user = this._userService.getLocalstorageUser();
        if(user){
            this.router.navigate(['/client']);
        }
    }

    onSubmit() {
        this._userService.login('login',this.loginForm.value).subscribe(
            response => { 
                localStorage.setItem('Authentication','Bearer ' + response.token);
                this._userService.getUser().subscribe(
                    response => {  
                        localStorage.setItem('User', JSON.stringify(response));
                        this.navbarComp.loadNavbar();
                    },
                    error => {},
                    () => {
                        this._clientService.changeMessage('1');
                        this.router.navigate(['/client']);
                    }
                );
            },
            error => { 
                this.hideAlert = false;
                setTimeout(() => {
                    this.hideAlert = true;
                }, 2000);
            },
            () => {}
        )
    }
}
