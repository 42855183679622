import { Component, OnInit } from '@angular/core';
import { FacturaService } from 'src/app/services/factura.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { CursServiceService } from 'src/app/services/curs-service.service';

@Component({
  selector: 'app-factura',
  templateUrl: './factura.component.html',
  styleUrls: ['./factura.component.css']
})
export class FacturaComponent implements OnInit {
  public facturi: any[];
  public facturiBase: any[];
  public filterProductVal: string;
  public filterProductVal2: string;
  public curentClientId: number;
  cursValutar: FormGroup;
  constructor(private formBuilder: FormBuilder, private _cursValutar: CursServiceService, private _facturaService: FacturaService, private _userService: UserService, private router: Router) { }

  ngOnInit() {
    this.buildFormFunc();
    this.onloading();
    //this.loadCursValutar();
  }

  loadCursValutar() {
    this._cursValutar.getCursValutar().subscribe(
      response => {
        this._userService.setLocalstorageCursValutar(response);
      },
      error => {
        localStorage.removeItem('Authentication');
        localStorage.removeItem('User');
        this.router.navigate(['/login']);
      },
      () => {}
    )
  }

  onloading() {
    this._facturaService.getFacturi().subscribe(
      response => {
        this.facturi = response;
        this.facturiBase = response;
      },
      error => {
        localStorage.removeItem('Authentication');
        localStorage.removeItem('User');
        this.router.navigate(['/login']);
      },
      () => {}
    )
  }

  filterData(type) {
    if (type == 1) {
      this.facturi = this.facturiBase.filter(prod => {
        return prod.name.toLowerCase().includes(this.filterProductVal.toLowerCase())
      });
    } else if (type == 2) {
      this.facturi = this.facturiBase.filter(prod => {
        return prod.clientName.toLowerCase().includes(this.filterProductVal2.toLowerCase())
      });
    }
  }

  onDeleteFactura(id) {
    this._facturaService.deleteFactura(id).subscribe(
      response => {
        this.onloading();
      },
      error => {},
      () => {}
    )
  }


  getUserId() {
    var user = this._userService.getLocalstorageUser();
    if (user)
      var user2 = JSON.parse(user);

    this.cursValutar.value.userId = user2.id;
  }

  onSubmitCursValutar(obj) {
    this.getUserId();
    this._cursValutar.insertCursValutar(this.cursValutar.value).subscribe(
      response => {
        this.onloading();
        $("#addCursValutar .close").click();
      },
      error => {},
      () => {}
    )
  }

  buildFormFunc() {
    this.cursValutar = this.formBuilder.group({
      cursCurent: ['', Validators.required],
      userId: ['', Validators.nullValidator]
    });
  }


}
