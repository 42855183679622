import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductsService } from '../../../services/products.service';
import { ClientService } from 'src/app/services/client.service';
import { UserService } from 'src/app/services/user.service';
import { OfertaService } from 'src/app/services/oferta.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-oferta-edit',
  templateUrl: './oferta-edit.component.html',
  styleUrls: ['./oferta-edit.component.css']
})
export class OfertaEditComponent implements OnInit {
  notReadyForFacturare: boolean = true;
  ofertaPretTotal: number = 0;
  clientForm: FormGroup;
  filterProductVal: string;
  filterProductVal2: string;
  avansTermenDate: string = "";
  tipClient: number = 0;
  mentiuneId: number = 0;
  procentajAvans: number = 0;

  client: any;
  facturareClient: any;
  private sub: any;

  ofertaId: number;
  oferta: FormGroup;
  ofertaFromDb: any;
  clients: any[];
  products: any[];
  productsBase: any[];
  productsForOferta: any[] = [];
  mentiuni: any[];

  constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private _productService: ProductsService, private _clientService: ClientService, private _userService: UserService, private _ofertaServce: OfertaService) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.ofertaId = +params['id'];
    });
    this.onloading(1);
    this.buildFormFunc();
  }

  onloading(option) {
    if (option == 1) {
      this._productService.getProduse().subscribe(
        response => {
          this.products = response;
          this.productsBase = response;
        },
        error => {},
        () => {}
      )
      this._productService.getProduseByOfertaId(this.ofertaId).subscribe(
        response => {
          this.productsForOferta = response.products;
          this.ofertaFromDb = response;
          this.procentajAvans = response.procentajAvans;
          this.avansTermenDate = response.avansTermenDate;
          this._clientService.getClients().subscribe(
            response => {
              this.clients = response;
              this.clients.forEach(cli => {
                if (cli.id == this.ofertaFromDb.clientId) {
                  this.client = cli;
                }
              });
              this._productService.getMentiuni().subscribe(
                response => {
                  this.mentiuni = response;
                  this.mentiuneId = +this.ofertaFromDb.mentiuneAvansTipId;
                  this.calculeazaProduseDinDb();
                },
                error => {},
                () => {}
              )
            },
            error => {},
            () => {}
          )
        },
        error => {},
        () => {}
      );

    } else if (option == 2) {
      this._clientService.getClients().subscribe(
        response => {
          this.clients = response;
        },
        error => {},
        () => {}
      )
    }
  }
  filterData(type) {
    if (type == 1) {
      this.products = this.productsBase.filter(prod => {
        return prod.description.toLowerCase().includes(this.filterProductVal.toLowerCase())
      });
    } else if (type == 2) {
      this.products = this.productsBase.filter(prod => {
        return prod.partNumber.toLowerCase().includes(this.filterProductVal2.toLowerCase())
      });
    }
  }
  addProdusToOferta(produs) {
    produs.quantity = 1;
    produs.ofertaProdusRelId = 0;
    produs.pretFinalCuProcentQuantity = 0;

    var isElm = this.productsForOferta.find(elm => elm.id == produs.id);
    if (!isElm) {
      this.productsForOferta.push(produs);
    }
  }

  stergeProdusDinOferta(produs) {
    var index = this.productsForOferta.indexOf(produs);
    this.productsForOferta.splice(index, 1);
  }

  seteazaTipClient(tipClient) {
    this.tipClient = tipClient;
  }

  onSubmitClient() {
    this._clientService.insertClient(this.clientForm.value).subscribe(
      response => {
        this.client = response;
        this.client = this.client.client;
        this.onloading(2);
        $("#adaugaClient .close").click();
      },
      error => {},
      () => {}
    )
  }

  buildOferta() {
    var user = this._userService.getLocalstorageUser();
    if (user)
      var user2 = JSON.parse(user);

    this.oferta.value.userId = user2.id;
    this.oferta.value.client = this.client;
    this.oferta.value.products = this.productsForOferta;
    this.oferta.value.mentiuneId = this.mentiuneId;
    this.oferta.value.procentajAvans = this.procentajAvans;
    this.oferta.value.avansTermenDate = this.avansTermenDate;
  }

  calculateProcent(pret, procent, produs) {
    if (!produs.transportRon) {
      produs.transportRon = 0;
    }
    pret = pret.toString();
    pret = pret.replace(',', '.');
    pret = pret.replace('EUR', '');
    pret = +pret;
    var valProcentaj = (procent / 100) * pret;
    produs.pretFinalCuProcent = ((+valProcentaj) + +(pret));
    produs.pretFinalCuProcent = produs.pretFinalCuProcent.toFixed(2);

    produs.pretFinalCuProcent = ((+produs.transportRon) + +(produs.pretFinalCuProcent));
    produs.pretFinalCuProcent = produs.pretFinalCuProcent.toFixed(2);

    produs.pretFinalCuProcentQuantity = produs.pretFinalCuProcent * produs.quantity;
    produs.pretFinalCuProcentQuantity = produs.pretFinalCuProcentQuantity.toFixed(2);
    this.calculeazaTotal()
  }

  calculeazaTotal() {
    this.ofertaPretTotal = 0;
    this.productsForOferta.forEach(element => {
      if (element.pretFinalCuProcentQuantity) {
        this.ofertaPretTotal = this.ofertaPretTotal + (+element.pretFinalCuProcentQuantity);
        this.ofertaPretTotal = +this.ofertaPretTotal.toFixed(2);
      }
    });
  }

  calculeazaProduseDinDb() {
    this.productsForOferta.forEach(element => {
      if (element) {
        if (element.denumire == "Stock") {
          element.pretFinal = element.stock;
        } else if (element.denumire == "Re") {
          element.pretFinal = element.re;
        } else if (element.denumire == "Bol") {
          element.pretFinal = element.bol;
        }
      }

      element.pretFinal = element.pretFinal.toString();
      element.pretFinal = element.pretFinal.replace(',', '.');
      element.pretFinal = element.pretFinal.replace('EUR', '');
      element.pretFinal = +element.pretFinal;
      var valProcentaj = (element.procentaj / 100) * element.pretFinal;

      element.pretFinalCuProcent = ((+valProcentaj) + +(element.pretFinal));
      element.pretFinalCuProcent = element.pretFinalCuProcent.toFixed(2);

      element.pretFinalCuProcent = ((+element.transportRon) + +(element.pretFinalCuProcent));
      element.pretFinalCuProcent = element.pretFinalCuProcent.toFixed(2);

      element.pretFinalCuProcentQuantity = element.pretFinalCuProcent * element.cantitate;
      element.pretFinalCuProcentQuantity = element.pretFinalCuProcentQuantity.toFixed(2);
    });

    this.ofertaPretTotal = 0;
    this.productsForOferta.forEach(element => {
      if (element.pretFinalCuProcentQuantity) {
        this.ofertaPretTotal = this.ofertaPretTotal + (+element.pretFinalCuProcentQuantity);
        this.ofertaPretTotal = +this.ofertaPretTotal.toFixed(2);
      }
    });
  }

  salveazaOfertaModificata() {
    this.productsForOferta.forEach(product => {
      if (!product.mentiunePozitiePeProdus) {
        product.mentiunePozitiePeProdus = ""
      }
    });
    this.ofertaFromDb.products = this.productsForOferta;
    this.ofertaFromDb.mentiuneId = this.mentiuneId;
    this.ofertaFromDb.procentajAvans = this.procentajAvans;
    this.ofertaFromDb.avansTermenDate = this.avansTermenDate;
    this.ofertaFromDb.client = this.client;
    this._ofertaServce.editareOferta(this.ofertaFromDb).subscribe(
      response => {},
      error => {},
      () => {
        this.router.navigate(['/ofertaView', this.ofertaId]);
      }
    )
  }

  printeazaFactura() {
    let printContent, printContentCumparator, popupWin;
    printContent = document.getElementById('printable').innerHTML;
    printContentCumparator = document.getElementById('printableCumparator').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>

        <head>
          <style>
            table {
                width:100%;
            }
            table, th, td {
                border: 1px solid black;
                border-collapse: collapse;
            }
            th, td {
                padding: 15px;
                text-align: left;
            }
            table#t01 tr:nth-child(even) {
                background-color: #eee;
            }
            table#t01 tr:nth-child(odd) {
                background-color: #fff;
            }
            table#t01 th {
                background-color: black;
                color: white;
            }
          </style>
        </head>

        <body onload="window.print();window.close()">

          <div style="display: flex;justify-content: center;align-items: center;height: 150px;flex-flow: column;">
            <img style="height: auto;max-width: 100px;" src="https://scontent.fotp3-1.fna.fbcdn.net/v/t1.0-9/51392693_760302547675362_1927380068867244032_n.png?_nc_cat=111&_nc_ht=scontent.fotp3-1.fna&oh=12153b7990a6e69cba25315424e16d86&oe=5D2158F1">
            <h2 style="font-family: monospace;margin-bottom:0;">Factura Proforma: ${this.ofertaFromDb.nrFiscal}</h2>
            <p style="margin-top:0;">${this.ofertaFromDb.creationDate}</p>
          </div>

          <div style="display:flex;height:200px;justify-content: center;align-items: center;">
            <div style="display:flex;width:100%;height:200px;justify-content: center;align-items: center;">
              
              <div style="display:flex;height:200px;width:50%;justify-content: center;align-items: center;flex-flow: column;">
                <div style="display:flex;height:200px;width:80%;justify-content: flex-start;align-items: flex-start;flex-flow: column;">
                  <h2 style="font-family: monospace;margin: 0;">Furnizor: </h2>
                  <h3 style="font-family: monospace;margin: 0;">SC Kovex Srl</h3>
                  <br>
                  <p style="font-family: monospace;margin: 0;">CIF : 1782052 </p>
                  <p style="font-family: monospace;margin: 0;">NR.ORD.REG.COM/AN: J30/875/2005</p>
                  <p style="font-family: monospace;margin: 0;">Sediul : Satu Mare, str Ady Endre 6. </p>
                  <p style="font-family: monospace;margin: 0;">Judetul : Satu Mare </p>
                  <p style="font-family: monospace;margin: 0;">Capital social : 200 </p>
                  <p style="font-family: monospace;margin: 0;">Cod IBAN : RO50OTPV280001128419RO01 </p>
                  <p style="font-family: monospace;margin: 0;">Banca : OTP SUC. SATU MARE </p>
                </div>
              </div>

              <div style="display:flex;height:200px;width:50%;justify-content: center;align-items: center;flex-flow: column;">
               <div style="display:flex;height:200px;width:80%;justify-content: center;align-items: flex-end;flex-flow: column;">
                ${printContentCumparator}
               </div>
              </div>

            </div>
          </div>

          <div>${printContent}</div>
          
        </body>

      </html>`
    );

    // <body onload="window.print();window.close()">${printContent}</body>

    popupWin.document.close();
  }

  buildFormFunc() {
    this.clientForm = this.formBuilder.group({
      clientName: ['', Validators.required],
      adresaSediu: ['', Validators.required],
      adresaLivrare: ['', Validators.required],
      cui: ['', Validators.required],
      numarInregistrare: ['', Validators.required],
      contBancar: ['', Validators.required],
      numeBanca: ['', Validators.required]
    });
    this.oferta = this.formBuilder.group({
      products: ['', Validators.required],
      descriere: ['', Validators.required],
      userId: ['', Validators.required],
      client: ['', Validators.required],
      creationDate: ['', Validators.nullValidator]
    });
  }

}
