import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import { ClientComponent } from '../../components/client/client.component';
import { ProdusComponent } from '../../components/produs/produs.component';
import { LoginComponent } from '../../components/login/login.component';
import { OfertareComponent } from '../../components/ofertare/ofertare/ofertare.component';
import { OfertaComponent } from 'src/app/components/oferta/oferta/oferta.component';
import { OfertaViewComponent } from 'src/app/components/oferta/oferta-view/oferta-view.component';
import { FacturaComponent } from 'src/app/components/factura/factura/factura.component';
import { OfertaEditComponent } from 'src/app/components/oferta/oferta-edit/oferta-edit.component';
import { FacturaViewComponent } from 'src/app/components/factura/factura-view/factura-view.component';
import { FacturaEditComponent } from 'src/app/components/factura/factura-edit/factura-edit.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'client', component: ClientComponent },
  { path: 'produs', component: ProdusComponent },
  { path: 'login', component: LoginComponent },
  { path: 'oferta', component: OfertaComponent},
  { path: 'ofertaView/:id', component: OfertaViewComponent},
  { path: 'ofertaEdit/:id', component: OfertaEditComponent},
  { path: 'ofertare', component: OfertareComponent },
  { path: 'factura', component: FacturaComponent},
  { path: 'facturaView/:id', component: FacturaViewComponent},
  { path: 'facturaEdit/:id', component: FacturaEditComponent},
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
