import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {
  public filterClientVal: string;
  public filterClientVal2: string;
  public filterClientVal3: string;
  public filterClientVal4: string;
  public curentClientId: number;
  public clients: any[];
  clientForm: FormGroup;
  personsContactForm: FormGroup;
  public clientsBase: any [];
  constructor(private formBuilder: FormBuilder,private _clientService: ClientService, private router: Router) { }

  ngOnInit() {
    this.onloading();
    this.buildFormFunc(null);
  }

  onloading(){
    this._clientService.getClients().subscribe(
      response => { 
          this.clients = response;
          this.clientsBase = response;
      },
      error => { 
          localStorage.removeItem('Authentication');
          localStorage.removeItem('User');
          this.router.navigate(['/login']);
      },
      () => {}
    )
  }

  filterData(type){
    if(type == 1){
      this.clients = this.clientsBase.filter(client => {
        return client.clientName.toLowerCase().includes(this.filterClientVal.toLowerCase())
      });
    }else if(type == 2){
      this.clients = this.clientsBase.filter(client => {
        return client.adresaSediu.toLowerCase().includes(this.filterClientVal2.toLowerCase())
      });
    }else if(type == 3){
      this.clients = this.clientsBase.filter(client => {
        return client.adresaLivrare.toLowerCase().includes(this.filterClientVal3.toLowerCase())
      });
    }else if(type == 4){
      this.clients = this.clientsBase.filter(client => {
        return client.cui.toLowerCase().includes(this.filterClientVal4.toLowerCase())
      });
    }
  }

  onSubmitClient(){
    this._clientService.insertClient(this.clientForm.value).subscribe(
      response => {
        this.onloading();
        $("#adaugaClient .close").click();
      },
      error => {},
      () => {}
    )
  }

  onDeleteClient(id){
    this._clientService.deleteClient(id).subscribe(
      response => {
        this.onloading();
        $("#adaugaClient .close").click();
      },
      error => {},
      () => {}
    )
  }

  onEditClient(data){
    this.buildFormFunc(data);
  }

  addPersoanaContactClient(data){
    this.curentClientId = data.id;
  }
  
  onSubmitPersoanContact(){
    this.personsContactForm.value.ClientId = this.curentClientId;
    this._clientService.insertPersoanaContact(this.personsContactForm.value).subscribe(
      response => {
        this.onloading();
        $("#addPersoanaContactClient .close").click();
      },
      error => {},
      () => {}
    )
  }

  onUpdateClient(){
    this._clientService.updateClient(this.clientForm.value).subscribe(
      response => {
        this.onloading();
        $("#editeazaClient .close").click();
      },
      error => {},
      () => {}
    )
  }

  buildFormFunc(obj) {
    if(obj != null){
      this.clientForm = this.formBuilder.group({
        id: [obj.id, Validators.required],
        clientName: [obj.clientName, Validators.required],
        adresaSediu: [obj.adresaSediu, Validators.required],
        adresaLivrare: [obj.adresaLivrare, Validators.required],
        cui: [obj.cui, Validators.required],
        numarInregistrare: [obj.numarInregistrare, Validators.required],
        contBancar: [obj.contBancar, Validators.nullValidator],
        numeBanca: [obj.numeBanca, Validators.nullValidator]
      });
    }else{
      this.clientForm = this.formBuilder.group({
        clientName: ['', Validators.required],
        adresaSediu: ['', Validators.required],
        adresaLivrare: ['', Validators.required],
        cui: ['', Validators.required],
        numarInregistrare: ['', Validators.required],
        contBancar: ['', Validators.nullValidator],
        numeBanca: ['', Validators.nullValidator]
      });
      this.personsContactForm = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        personalNumber: ['', Validators.nullValidator],
        jobNumber: ['', Validators.nullValidator],
        ClientId: [0, Validators.required]
      });
    }
  }
}
