import { Component, OnInit } from '@angular/core';
import { OfertaService } from 'src/app/services/oferta.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-oferta',
  templateUrl: './oferta.component.html',
  styleUrls: ['./oferta.component.css']
})
export class OfertaComponent implements OnInit {
  public oferte: any[];
  public oferteBase: any[];
  filterProductVal: string;
  filterProductVal2: string;
  isFacturaProforma: boolean = false;
  isLastDateModified:  boolean = false;
  constructor(private _ofertaService: OfertaService, private router: Router) { }

  ngOnInit() {
    this.onloading();
  }

  onloading() {
    this._ofertaService.getOferte().subscribe(
      response => {
        this.oferte = response;
        this.oferteBase = response;
      },
      error => {
        localStorage.removeItem('Authentication');
        localStorage.removeItem('User');
        this.router.navigate(['/login']);
      },
      () => {}
    )
  }
  filterData(type) {
    if (type == 1) {
      this.oferte = this.oferteBase.filter(prod => {
        return prod.name.toLowerCase().includes(this.filterProductVal.toLowerCase())
      });
    } else if (type == 2) {
      this.oferte = this.oferteBase.filter(prod => {
        return prod.clientName.toLowerCase().includes(this.filterProductVal2.toLowerCase())
      });
    } else if (type == 3) {

      if (this.isFacturaProforma) {
        this.oferte = this.oferteBase.filter(prod => {
          return prod.isFacturaProforma.toLowerCase().includes('1')
        });
      }else {
        this.oferte = this.oferteBase;
      }
    } else if (type == 4) {
      if (this.isLastDateModified) {
        this.oferte = this.oferteBase.sort(function (a, b) {
          if(a.modifiedDate == null)
            a.modifiedDate = "";
          if(b.modifiedDate == null)
            b.modifiedDate = "";
          return <any>a.modifiedDate - <any>b.modifiedDate;
        });
      }else {
        this.oferte = this.oferteBase;
      }
    }
    
  }

  onDeleteOferta(id) {
    this._ofertaService.deleteOferta(id).subscribe(
      response => {
        this.onloading();
      },
      error => {},
      () => {}
    )
  }

}
