import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FacturaService {
  // private _baseAddress: string = "http://www.app.owlsoftware.ro/api/";
  // private _baseAddress: string = "http://localhost:8888/api/";
  private _baseAddress: string = "http://www.kovexapp.ro/api/";

  constructor(private _http: HttpClient) { }

  insertFactura(oferta){
    var address = this._baseAddress + 'factura';  
    return this._http.post<any>(address, oferta, { headers: this._buildHeader() });  
  }

  editareFactura(oferta){
    var address = this._baseAddress + 'factura';  
    return this._http.put(address, oferta, { headers: this._buildHeader() });  
  }

  getFacturi(){
    var address = this._baseAddress + 'facturi';  
    return this._http.get<any[]>(address, { headers: this._buildHeader() });  
  }

  deleteFactura(id){
    var address = this._baseAddress + 'factura/' + id;  
    return this._http.delete(address, { headers: this._buildHeader() });  
  }

  getFacturiByProdusId(id){
    var address = this._baseAddress + 'facturi/produsId/' + id;  
    return this._http.get<any>(address, { headers: this._buildHeader() });  
  }

  private _buildHeader(isFile: boolean = false): HttpHeaders {

    let headers = new HttpHeaders();
    let authTokken = localStorage['Authentication'];
    if(isFile){
      // headers = headers.append('Content-Type', 'multipart/form-data');
    }else{
      headers = headers.append('Content-Type', 'application/json');
    }
    headers = headers.append('Authorization', authTokken);
    return headers;
  }
}
