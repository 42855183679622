import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductsService } from '../../services/products.service';

import { Router } from '@angular/router';
import { FacturaService } from 'src/app/services/factura.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-produs',
  templateUrl: './produs.component.html',
  styleUrls: ['./produs.component.css']
})
export class ProdusComponent implements OnInit {
  filterProductVal: string;
  filterProductVal2: string;
  products: any[];
  productsBase: any[];
  produsPeFacturi: any[];
  produsIndividualForm: FormGroup;
  isStockSatuMare: boolean = false;
  csvFile: FileList;
  cursValutar: any;
  cursValutarInt: number;
  constructor(private formBuilder: FormBuilder, private _productService: ProductsService, private _facturaService: FacturaService, private router: Router,private _userService: UserService) { }

  ngOnInit() {
    this.onloading();
    this.buildFormFunc(null);
  }

  onloading(){
    this.cursValutar = this._userService.getLocalstorageCursValutar();
    if (this.cursValutar) {
      this.cursValutar = JSON.parse(this.cursValutar);
      this.cursValutarInt = parseFloat(this.cursValutar.cursCurent);
    }
    this._productService.getProduse().subscribe(
      response => { 
          this.products = response;
          this.productsBase = response;
      },
      error => { 
          localStorage.removeItem('Authentication');
          localStorage.removeItem('User');
          this.router.navigate(['/login']);
      },
      () => {}
    )
  }

  filterData(type){
    if(type == 1){
      this.products = this.productsBase.filter(prod => {
        return prod.description.toLowerCase().includes(this.filterProductVal.toLowerCase())
      });
    }else if(type == 2){
      this.products = this.productsBase.filter(prod => {
        return prod.partNumber.toLowerCase().includes(this.filterProductVal2.toLowerCase())
      });
    }else if(type == 3){
      if (this.isStockSatuMare) {
        this.products = this.productsBase.filter(prod => {
          return prod.stockSatuMare.toLowerCase().includes('1')
        });
      }else {
        this.products = this.productsBase;
      }
    }
  }

  onSubmitProdus(){
    if(!this.produsIndividualForm.value.produseInStockSM)
      {
        this.produsIndividualForm.value.produseInStockSM = 0;
      }
    this._productService.insertIndividualProduct(this.produsIndividualForm.value).subscribe(
      response => {
        this.onloading();
        $("#adaugaProdusIndividual .close").click();
      },
      error => {},
      () => {}
    )
  }

  onEditProdus(data){
    var newData = data;
    if(data.stockSatuMare.toLowerCase().includes('1')){
      newData.stockSatuMare = true;
    }else{
      newData.stockSatuMare = false;
    }
    this.buildFormFunc(newData);
  }


  onUpdateProdus(){
    this._productService.updateIndividualProduct(this.produsIndividualForm.value).subscribe(
      response => {
        this.onloading();
        $("#editeazaProdusIndividual .close").click();
      },
      error => {},
      () => {}
    )
  }

  setFile(files: FileList){
    this.csvFile = files;
  }

  
  insertCsv(){
    if(this.csvFile && this.csvFile.length > 0) {
        let file : File = this.csvFile.item(0); 
        let formData: FormData = new FormData();
        formData.append('file',file,file.name);
        this._productService.insertCsv(formData).subscribe(
          response => { 
              $("#adaugaProdusCsv .close").click();
          },
          error => {},
          () => {}
      );
    }
  }

  onDeleteProdus(id){
    this._productService.deleteProdus(id).subscribe(
      response => {
        this.onloading();
      },
      error => {},
      () => {}
    )
  }

  veziFacturi(id){
    this._facturaService.getFacturiByProdusId(id).subscribe(
      response => {
        this.calculeazaValoareProdusePeFacturi(response);
        
      },
      error => {},
      () => {}
    )
  }

  calculeazaValoareProdusePeFacturi(produsPeFacturi:any){
    this.calculateProcent(produsPeFacturi)
    this.produsPeFacturi = produsPeFacturi;
    console.log(this.produsPeFacturi)
  }

  calculateProcent(produsPeFacturi:any) {
    produsPeFacturi.facturi.forEach(element => {
      if (element) {
        if (element.denumire == "Stock") {
          element.pretFinal = produsPeFacturi.stock;
        } else if (element.denumire == "Re") {
          element.pretFinal = produsPeFacturi.re;
        } else if (element.denumire == "Bol") {
          element.pretFinal = produsPeFacturi.bol;
        }
      }

      element.pretFinal = element.pretFinal.toString();
      element.pretFinal = element.pretFinal.replace(',', '.');
      element.pretFinal = element.pretFinal.replace('EUR', '');
      element.pretFinal = +element.pretFinal;
      var valProcentaj = (element.procentaj / 100) * element.pretFinal;

      element.pretFinalCuProcent = ((+valProcentaj) + +(element.pretFinal));
      element.pretFinalCuProcent = element.pretFinalCuProcent.toFixed(2);
      
      element.pretFinalCuProcent = ((+element.transportRon) + +(element.pretFinalCuProcent));
      element.pretFinalCuProcent = element.pretFinalCuProcent.toFixed(2);

      element.pretFinalCuProcentLei = this.cursValutarInt * element.pretFinalCuProcent;
      element.pretFinalCuProcentLei = element.pretFinalCuProcentLei.toFixed(2);

      element.pretFinalCuProcentQuantityLei = element.pretFinalCuProcentLei * element.cantitate;
      element.pretFinalCuProcentQuantityLei = element.pretFinalCuProcentQuantityLei.toFixed(2);

      element.pretFinalCuProcentQuantityTVALei = (element.pretFinalCuProcentQuantityLei * 19) / 100;
      element.pretFinalCuProcentQuantityTVALei = this.calculeazaCorectDecimale (element.pretFinalCuProcentQuantityTVALei);
    });
  }

  calculeazaCorectDecimale(numar:number){
    var numarStr = "";
    if(numar.toString().indexOf(".") != -1 ) // daca are decimale 
    {
      var calcul = Math.round
      (
      parseFloat(
      numar.toFixed(4).substring(numar.toFixed(4).length -3,numar.toFixed(4).length - 2) + "."
      +
  
      Math.round
      (
      parseFloat(
      numar.toFixed(4).substring(numar.toFixed(4).length -2,numar.toFixed(4).length -1) + "." +numar.toFixed(4).substring(numar.toFixed(4).length - 1,numar.toFixed(4).length - 0) 
      )
      )
  
      )
      ).toString()
      var numarStr = numar.toFixed(2).substring(0,numar.toFixed(2).length -1) + (calcul.length == 2 ? 0 : calcul)
    }else
    {
      numarStr = numar.toFixed(2);
    }
    return numarStr;
  }

  buildFormFunc(obj) {
    if(obj != null){
      this.produsIndividualForm = this.formBuilder.group({
        id: [obj.id, Validators.required],
        furnizor: [obj.furnizor, Validators.required],
        partNumber: [obj.partNumber, Validators.required],
        description: [obj.description, Validators.required],
        uom: [obj.uom, Validators.required],
        re: [obj.re, Validators.required],
        stock: [obj.stock, Validators.required],
        stockSatuMare: [obj.stockSatuMare, Validators.nullValidator],
        produseInStockSM: [obj.produseInStockSM, Validators.nullValidator]
      });
    }else{
      this.produsIndividualForm = this.formBuilder.group({
        furnizor: ['', Validators.required],
        partNumber: ['', Validators.required],
        description: ['', Validators.required],
        uom: ['', Validators.required],
        re: ['', Validators.required],
        stock: ['', Validators.required],
        stockSatuMare: ['', Validators.nullValidator],
        produseInStockSM: ['', Validators.nullValidator]
      });
    }
  }

}
