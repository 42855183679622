import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { ClientService } from 'src/app/services/client.service';
import { UserService } from 'src/app/services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FacturaService } from 'src/app/services/factura.service';
import { OfertaService } from 'src/app/services/oferta.service';

@Component({
  selector: 'app-oferta-view',
  templateUrl: './oferta-view.component.html',
  styleUrls: ['./oferta-view.component.css']
})
export class OfertaViewComponent implements OnInit {
  id: number;
  private sub: any;
  oferta: any;
  client: any;
  cursValutar: any;
  cursValutarInt: number;
  productsBaseOferta: any[];
  mentiuni: any[];
  restDePlata: any[];
  mentiune: string;
  mentiuneId: number = 0;
  restDePlataId: number = 0;
  ofertaPretTotal: number = 0;
  ofertaPretTotalLei: number = 0;
  ofertaPretTotalProduse: number = 0;
  ofertaPretTotalTVAuri: number = 0;
  mentiuneSuma: number = 0;
  ofertaFiscala: FormGroup;
  restPlataDataTermen: string = "";
  stringEmpty: string = "";

  constructor(private formBuilder: FormBuilder, private _ofertaService: OfertaService, private router: Router, private route: ActivatedRoute, private _productService: ProductsService, private _clientService: ClientService, private _userService: UserService, private _facturaServce: FacturaService) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
    });
    this.onloading();
    this.buildFormFunc();
  }

  onloading() {
    this.cursValutar = this._userService.getLocalstorageCursValutar();
    if (this.cursValutar) {
      this.cursValutar = JSON.parse(this.cursValutar);
      this.cursValutarInt = parseFloat(this.cursValutar.cursCurent);
    }
    this._productService.getMentiuni().subscribe(
      response => {
        this.mentiuni = response
        this._productService.getRestDePlata().subscribe(
          response => {
            this.restDePlata = response

            this._productService.getProduseByOfertaId(this.id).subscribe(
              response => {
                this.oferta = response;
                this.productsBaseOferta = response;
                this.getClient(this.oferta.clientId);
                this.getMentiune();
                this.calculateProcent();
              },
              error => {},
              () => {}
            )
          },
          error => {},
          () => {}
        )
      },
      error => {},
      () => {}
    )
  }
  getMentiune() {
    this.mentiuni.forEach(mentiune => {
      if (mentiune.id == this.oferta.mentiuneAvansTipId) {
        this.mentiune = mentiune.denumire;
        this.mentiuneId = mentiune.id;
      }
    });
  }
  factureazaOferta(isFiscala) {
    if (isFiscala) {
      //introdu datele in tabele ca fiind factura fiscala
      this.salveazaCaFacturaFiscala();
    } else {
      //doar tipareste
      this.printeazaFactura(false);
    }
  }

  salveazaCaFacturaFiscala() {
    this.buildOferta();
    this._facturaServce.insertFactura(this.ofertaFiscala.value).subscribe(
      response => {
        this.router.navigate(['/facturaView', response.id]);
      },
      error => {},
      () => {}
    )
  }

  promoveazaCaProforma() {
    this._ofertaService.addOfertaToProforma(this.oferta).subscribe(
      response => {
        this.onloading();
      },
      error => {},
      () => {}
    )
  }

  buildOferta() {
    var user = this._userService.getLocalstorageUser();
    if (user)
      var user2 = JSON.parse(user);

    this.ofertaFiscala.value.userId = user2.id;
    this.ofertaFiscala.value.client = this.client;
    this.ofertaFiscala.value.products = this.oferta.products;
    this.ofertaFiscala.value.hasAvans = this.oferta.hasAvans;
    this.ofertaFiscala.value.avansAchitatLei = this.mentiuneSuma;
    this.ofertaFiscala.value.restDePlataId = this.restDePlataId;
    this.ofertaFiscala.value.restPlataDataTermen = this.restPlataDataTermen;
    this.ofertaFiscala.value.nrProforma = this.oferta.nrFacturaProforma != null ? this.oferta.nrFacturaProforma : this.oferta.nrOferta;
  }

  calculateProcent() {
    this.oferta.products.forEach(element => {
      if (element) {
        if (element.denumire == "Stock") {
          element.pretFinal = element.stock;
        } else if (element.denumire == "Re") {
          element.pretFinal = element.re;
        } else if (element.denumire == "Bol") {
          element.pretFinal = element.bol;
        }
      }

      element.pretFinal = element.pretFinal.toString();
      element.pretFinal = element.pretFinal.replace(',', '.');
      element.pretFinal = element.pretFinal.replace('EUR', '');
      element.pretFinal = +element.pretFinal;
      var valProcentaj = (element.procentaj / 100) * element.pretFinal;

      element.pretFinalCuProcent = ((+valProcentaj) + +(element.pretFinal));
      element.pretFinalCuProcent = element.pretFinalCuProcent.toFixed(2);

      element.pretFinalCuProcent = ((+element.transportRon) + +(element.pretFinalCuProcent));
      element.pretFinalCuProcent = element.pretFinalCuProcent.toFixed(2);

      element.pretFinalCuProcentLei = this.cursValutarInt * element.pretFinalCuProcent;
      element.pretFinalCuProcentLei = element.pretFinalCuProcentLei.toFixed(2);

      element.pretFinalCuProcentQuantityLei = element.pretFinalCuProcentLei * element.cantitate;
      element.pretFinalCuProcentQuantityLei = this.calculeazaCorectDecimale(element.pretFinalCuProcentQuantityLei);

      element.pretFinalCuProcentQuantityTVALei = (element.pretFinalCuProcentQuantityLei * 19) / 100;
      element.pretFinalCuProcentQuantityTVALei = this.calculeazaCorectDecimale (element.pretFinalCuProcentQuantityTVALei);
    });
    this.calculeazaTotal();
  }

  calculeazaTotal() {
    this.ofertaPretTotal = 0;
    this.ofertaPretTotalLei = 0;
    this.ofertaPretTotalProduse = 0;
    this.ofertaPretTotalTVAuri = 0;

    this.oferta.products.forEach(element => {
      if (element.pretFinalCuProcentQuantityTVALei) {
        this.ofertaPretTotalProduse = this.ofertaPretTotalProduse + (+element.pretFinalCuProcentQuantityLei); 
        this.ofertaPretTotalTVAuri = this.ofertaPretTotalTVAuri + (+element.pretFinalCuProcentQuantityTVALei);
        this.ofertaPretTotal = this.ofertaPretTotal + (+element.pretFinalCuProcentQuantityLei) + (+element.pretFinalCuProcentQuantityTVALei) ;
        this.ofertaPretTotalLei = +this.ofertaPretTotal;
      };
    });
    this.ofertaPretTotalTVAuri = +this.calculeazaCorectDecimale(this.ofertaPretTotalTVAuri);
    this.ofertaPretTotalProduse = +this.calculeazaCorectDecimale(this.ofertaPretTotalProduse);
    this.ofertaPretTotalLei = +this.calculeazaCorectDecimale(this.ofertaPretTotalLei);

    this.mentiuneSuma = (this.oferta.procentajAvans / 100) * this.ofertaPretTotalLei;
    this.mentiuneSuma = +this.calculeazaCorectDecimale(+this.mentiuneSuma);
  }

  calculeazaCorectDecimale(numar:number){
    var numarStr = "";
    if(numar.toString().indexOf(".") != -1 ) // daca are decimale 
    {
      var calcul = Math.round
      (
      parseFloat(
      numar.toFixed(4).substring(numar.toFixed(4).length -3,numar.toFixed(4).length - 2) + "."
      +
  
      Math.round
      (
      parseFloat(
      numar.toFixed(4).substring(numar.toFixed(4).length -2,numar.toFixed(4).length -1) + "." +numar.toFixed(4).substring(numar.toFixed(4).length - 1,numar.toFixed(4).length - 0) 
      )
      )
  
      )
      ).toString()
      var numarStr = numar.toFixed(2).substring(0,numar.toFixed(2).length -1) + (calcul.length == 2 ? 0 : calcul)
    }else
    {
      numarStr = numar.toFixed(2);
    }
    return numarStr;
  }

  getClient(clientId) {
    this._clientService.getClientById(clientId).subscribe(
      response => {
        this.client = response;
      },
      error => {},
      () => {}
    )
  }

  printeazaFactura(isFiscala) {
    var titlu = "Oferta";

    if (this.oferta.isFacturaProforma == "1") {
      titlu = "Factura proforma"
    }

    if (isFiscala) {
      titlu = "Factura fiscala"
    }
    let printContent, printContentCumparator, printableText, popupWin;
    printContent = document.getElementById('printable').innerHTML;
    printableText = document.getElementById('printableText').innerHTML;
    printContentCumparator = document.getElementById('printableCumparator').innerHTML;
    popupWin = window.open('printPage', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>

        <head>
          <style>
            .printableText {
              margin-left:50px;
            }
            .printableText p {
                font-size: 12px;
                margin: 0;
            }
            .printableText h4 {
                font-size: 14px;
                margin: 0;
            }
            table {
                width:100%;
            }
            table, th, td {
                border: 1px solid black;
                border-collapse: collapse;
            }
            th, td {
                padding: 15px;
                text-align: left;
            }
            table#t01 tr:nth-child(even) {
                background-color: #eee;
            }
            table#t01 tr:nth-child(odd) {
                background-color: #fff;
            }
            table#t01 th {
                background-color: black;
                color: white;
            }
          </style>
        </head>

        <body onload="window.print();window.close()">

          <div style="display: flex;justify-content: center;align-items: center;height: 150px;flex-flow: column;">
            <img style="height: auto;max-width: 100px;" src="http://www.kovexapp.ro/kovex.png">
            <h2 style="margin-bottom:0;">${titlu}: ${this.oferta.isFacturaProforma == "1" ? this.oferta.nrFacturaProforma : this.oferta.nrOferta}</h2>
            <p style="margin-top:0;"> ${this.oferta.creationDate} </p>
          </div>

          <div style="display:flex;height:200px;justify-content: center;align-items: center;">
            <div style="display:flex;width:100%;height:200px;justify-content: center;align-items: center;">
              
              <div style="display:flex;height:200px;width:50%;justify-content: center;align-items: center;flex-flow: column;">
                <div style="display:flex;height:200px;width:60%;justify-content: flex-start;align-items: flex-start;flex-flow: column;">
                  <h3 style="margin: 0;">FURNIZOR: </h3>
                  <h5 style="margin: 0;">SC KOVEX SRL</h5>
                  <br>
                  <p style="font-size: 12px;margin: 0;">CIF : RO17823052 </p>
                  <p style="font-family: monospace;margin: 0;">NR.ORD.REG.COM/AN: J30/875/2005</p>
                  <p style="font-size: 12px;margin: 0;">SEDIUL : Satu Mare, str Ady Endre 6. </p>
                  <p style="font-size: 12px;margin: 0;">JUDETUL : Satu Mare </p>
                  <p style="font-size: 12px;margin: 0;">CAPITAL SOCIAL : 200 </p>
                  <p style="font-size: 12px;margin: 0;">COD IBAN : RO50OTPV280001128419RO01 </p>
                  <p style="font-size: 12px;margin: 0;">BANCA : OTP SUC. SATU MARE </p>
                </div>
              </div>

              <div style="display:flex;height:200px;width:50%;justify-content: center;align-items: center;flex-flow: column;">
               <div style="display:flex;height:200px;width:60%;justify-content: center;align-items: flex-end;flex-flow: column;">
                ${printContentCumparator}
               </div>
              </div>

            </div>
          </div>

          <div>${printContent}</div>
          <div>${printableText}</div>
        </body>

      </html>`
    );

    // <body onload="window.print();window.close()">${printContent}</body>

    popupWin.document.close();
  }

  buildFormFunc() {
    this.ofertaFiscala = this.formBuilder.group({
      products: ['', Validators.required],
      descriere: ['', Validators.required],
      userId: ['', Validators.required],
      client: ['', Validators.required],
      hasAvans: ['', Validators.required],
      avansAchitatLei: ['', Validators.required],
      restDePlataId: ['', Validators.required],
      restPlataDataTermen: ['', Validators.required],
      nrProforma: ['', Validators.nullValidator]
    });
  }

}
